import { template as template_5fa2a5b5143943ddb8cc56eba0f39c34 } from "@ember/template-compiler";
const WelcomeHeader = template_5fa2a5b5143943ddb8cc56eba0f39c34(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
