import { template as template_a5c52d991cf844999379de694019f7e2 } from "@ember/template-compiler";
const FKLabel = template_a5c52d991cf844999379de694019f7e2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
